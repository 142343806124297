import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class StoreReportsProvider extends HttpRequest {
  getStockAging (query) {
    this.setHeader(getAuthToken())
    return this.get('store-reports/stock-aging', query)
  }

  getSalesProducts (query) {
    this.setHeader(getAuthToken())
    return this.get('store-reports/sales-products', query)
  }

  getBestSellerProducts (query) {
    this.setHeader(getAuthToken())
    return this.get('store-reports/best-seller-products', query)
  }
}

export default StoreReportsProvider
